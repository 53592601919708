<template>
<vx-card no-shadow class="card-overflow" title="Buy Season Pass 1">
    <template slot="actions">
        <vs-button @click="goBack" color="dark">Back</vs-button>
    </template>
    <div class="fill-row-loading">
        <div :class="{'activeLoading':activeLoading}" id="loading-default" class="vs-con-loading__container loading-example">
        </div>
    </div>
    <ValidationObserver ref="form">
        <form-wizard color="rgba(var(--vs-primary), 1)" title="" shape="square" stepSize="sm" backButtonText="Go Back" :subtitle="null" finishButtonText="Submit" @on-complete="bookPass">
            <tab-content title="Select Season Pass" icon="ti-credit-card" class="mb-base" :before-change="handlePass">
                <div class="vx-row w-full flex justify-center mt-base">
                    <div class="vx-col w-full lg:w-1/3 sm:w-1/2 mb-base" v-for="(pass, index) in passData" :key="index" :data="pass">
                        <vx-card class="p-2 border-dark" card-border no-shadow>
                            <div slot="actions">

                            </div>
                            <div class="text-center">
                                <h4 class="mb-3">{{ pass.name }}</h4>
                                <span class="">Period: {{ pass.startDate | formatLongDate }} to
                                    {{ pass.endDate | formatLongDate }}</span>
                            </div>
                            <div class="text-center m-base">
                                <vs-avatar :color="pass.statusColor ? pass.statusColor : '#ccc'" size="large" icon-pack="feather" icon="icon-credit-card" />
                                <h1>{{ pass.priceIncGst | formatCurrency }}</h1>
                                <span class="">Per Vehicle</span>
                            </div>
                            <vs-button class="w-full mt-6" icon-pack="feather" :icon="
                    selectedPass.id === pass.id
                      ? 'icon-check-circle'
                      : 'icon-circle'
                  " @click="setSelectedPass(pass)" :color="selectedPass.id === pass.id ? 'success' : 'danger'">Buy</vs-button>
                        </vx-card>
                    </div>
                </div>
            </tab-content>
            <tab-content title="Vehicle Registration" icon="ti-car" size="small" class="mb-base" :before-change="handleNext">
                <ValidationObserver ref="dataForm">
                    <form autocomplete="off" class="mt-base data-form-card">
                        <div class="vx-row mb-2">
                            <div class="vx-col sm:w-1/4 w-full">
                                <span class="text-right">State of Registration:</span>
                            </div>
                            <div class="vx-col sm:w-1/4 w-full">
                                <ValidationProvider name="state" class="w-full" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                    <vs-select v-model="carRegistrationPayload.registrationState" name="state" class="w-full">
                                        <vs-select-item :key="index" :value="item.code" :text="item.name" v-for="(item, index) in stateList" />
                                    </vs-select>
                                    <span class="text-danger text-sm">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="vx-row mb-2">
                            <div class="vx-col sm:w-1/4 w-full">
                                <span class="text-right">Registration Plate:</span>
                            </div>
                            <div class="vx-col sm:w-1/4 w-full">
                                <ValidationProvider class="w-full" :rules="{ regex: /^[a-zA-Z0-9]+$/, required: true, max: 10 }" v-slot="{ errors }" :custom-messages="customMessages" name="licencePlate">
                                    <vs-input class="w-full" name="licencePlate" :disabled="thisIsMyCarConfirm" @keyup="changeletter" v-model="carRegistrationPayload.registrationPlate" autocomplete="nope" />
                                    <span class="text-danger text-sm">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>

                        </div>
                        <div class="vx-row mb-2">
                            <div class="vx-col sm:w-1/4 w-full">
                            </div>
                            <div class="vx-col sm:w-1/4 w-full">
                                <vs-button v-if="!thisIsMyCarConfirm" color="dark" @click.prevent="findCar">Validate</vs-button>
                                <vs-button v-if="thisIsMyCarConfirm" @click.prevent="changeCar" color="dark">Change</vs-button>
                            </div>
                        </div>
                        <div class="vx-row mb-2" v-if="ifValidCar">
                            <div class="vx-col sm:w-1/4 w-full">
                                <span class="text-right">Registration Expires:</span>
                            </div>
                            <div class="vx-col sm:w-1/3 w-full">
                                <span class="text-primary font-bold">
                                    {{
                          carRegistrationPayload.registrationExpiryDate
                            | formatLongDate
                        }}</span>
                            </div>
                        </div>
                        <div class="vx-row mb-2" v-if="ifValidCar">
                            <div class="vx-col sm:w-1/4 w-full">
                                <span class="text-right">Registration Status:</span>
                            </div>
                            <div class="vx-col sm:w-1/3 w-full">
                                <span class="text-primary font-bold">
                                    {{ carRegistrationPayload.registrationStatus }}</span>
                            </div>
                        </div>
                        <div class="vx-row mb-2" v-if="ifValidCar">
                            <div class="vx-col sm:w-1/4 w-full">
                                <span class="text-right">Vehicle Name/Model:</span>
                            </div>
                            <div class="vx-col sm:w-1/3 w-full">
                                <ValidationProvider class="w-full" :rules="{
                          regex: /^[a-zA-Z0-9 -]+$/,
                          required: true,
                          max: 50
                        }" v-slot="{ errors }" :custom-messages="customMessages" name="vehicleName">
                                    <vs-input class="w-full" v-model="carRegistrationPayload.model" autocomplete="nope" />
                                    <span class="text-danger text-sm">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div v-if="ifValidCar">
                            <div class="vx-row mb-2">
                                <div class="vx-col sm:w-1/4 w-full">
                                    <span class="text-right">&nbsp;</span>
                                </div>
                                <div class="vx-col sm:w-2/3 w-full">
                                    <vs-checkbox class="mr-2 text-left" v-model="thisIsMyCarConfirm">
                                    </vs-checkbox>
                                    <span class="text-left">
                                        I confirm that I own this vehicle, or if it is not my
                                        vehicle, that I have the necessary authority to drive
                                        it at Boat Harbour.
                                    </span>
                                </div>
                            </div>
                            <div class="vx-row mb-2">
                                <div class="vx-col sm:w-1/4 w-full">
                                    <span class="text-right">&nbsp;</span>
                                </div>
                                <div class="vx-col sm:w-2/3 w-full">
                                    <vs-checkbox class="mr-2 text-left" v-model="thisconditionConfirm">
                                    </vs-checkbox>
                                    <span class="text-left">
                                        I confirm that I have read and accept the <a @click="handleOpenTerms" class="cursor-pointer">terms and conditions</a>
                                    </span>
                                </div>
                            </div>
                            <div class="vx-row mb-2">
                                <div class="vx-col sm:w-1/4 w-full">
                                    <span class="text-right">&nbsp;</span>
                                </div>
                                <div class="vx-col sm:w-2/3 w-full">
                                    <span class="text-left">
                                        <vs-checkbox class="mr-2 text-left" v-model="thisAcknowledgeConfirm">
                                        </vs-checkbox>
                                        I acknowledge that the purchase of a season pass does not guarantee me entry to Boat Harbour Park. Per the <a @click="handleOpenTerms" class="cursor-pointer">terms and conditions</a>, access on Sundays and Public Holidays between October and February are now by invitation only with the invitations allocated via a ballot process. Other days may also be subject to restrictions due to the prevailing conditions and will be updated on the website <a href="https://www.holtestate.com.au/bhp1" target="_blank" class="cursor-pointer">here</a> from time to time
                                    </span>
                                </div>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
                <ViewFile v-if="isOpenTerms" :isOpenTerms="isOpenTerms" @closeWindow="closeWindow" :URL="fileURL" />
            </tab-content>
            <tab-content title="Checkout" icon="ti-credit-card" class="mb-base">
                <div class="vx-row mb-2">
                    <div class="vx-col sm:w-1/2 w-full">
                        <vx-card title="Vehicle Details" class="mt-base">
                            <div class="flex justify-between mb-2">
                                <span class="text-right">Vehicle</span>
                                <span class="font-semibold">
                                    {{ carRegistrationPayload.model }}</span>
                            </div>
                            <div class="flex justify-between mb-2">
                                <span class="text-right">Registration Plate</span>
                                <span class="font-semibold">
                                    {{ carRegistrationPayload.registrationPlate }} ({{
                      carRegistrationPayload.registrationState
                    }})</span>
                            </div>
                            <div class="flex justify-between mb-2">
                                <span class="text-right">Season Pass</span>
                                <span class="text-success">{{ selectedPass.name }}</span>
                            </div>
                            <div class="flex justify-between mb-5 mt-5">
                                <vs-input v-model="promo" class="m-0 mr-5" placeholder="Enter Promocode"></vs-input>
                                <vs-button v-show="discountPercentage === 0" :disabled="promo === ''" @click="validatePromo" color="success">Apply Promo</vs-button>
                                <vs-button v-show="discountPercentage" @click="removePromo" color="dark">Remove Promo</vs-button>
                            </div>
                            <vs-divider />
                            <div class="flex justify-between mb-5">
                                <span class="text-right">Total Amount</span>
                                <span class="font-semibold">{{
                    selectedPass.priceIncGst | formatCurrency
                  }}</span>
                            </div>
                            <div v-if="priceAfterDeduction>=0">
                                <div class="flex justify-between mb-5">
                                    <span class="text-right">Promo-Code Applied :</span>
                                    <span class="font-semibold">{{
                      -`${discountPercentage}` | formatPercentage
                    }}</span>
                                </div>
                                <div class="flex justify-between">
                                    <span class="text-right">Amount Payable</span>
                                    <span class="font-semibold">{{
                      priceAfterDeduction | formatCurrency
                    }}</span>
                                </div>
                            </div>
                        </vx-card>
                    </div>
                    <div class="vx-col sm:w-1/2 w-full">
                        <VCreditCard v-show="this.priceAfterDeduction > 0" @change="creditInfoChanged" :trans="translations" />
                    </div>
                </div>
            </tab-content>
        </form-wizard>
    </ValidationObserver>
</vx-card>
</template>

<script>
import {
    v4 as uuid
} from "uuid";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import moment from "moment";
import VCreditCard from "v-credit-card";
import "v-credit-card/dist/VCreditCard.css";
import {
    customMessages
} from "./../../filters/validationmessage";
import {
    FormWizard,
    TabContent
} from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import VxCard from "../../components/vx-card/VxCard.vue";

import {
    promoData
} from "../../store/api/promo";
import {
    carData
} from "../../store/api/car";
import {
    productData
} from "../../store/api/product";
import {
    profileData
} from "../../store/api/profile";
import {
    treeMixData
} from "../../store/api/treeMix";

import AuthService from "../../services/AuthService";
const authservice = new AuthService();

const translations = {
    name: {
        label: "Cardholder Name",
        placeholder: "Cardholder Name"
    },
    card: {
        label: "Card Number",
        placeholder: "Card Number"
    },
    expiration: {
        label: "Expires"
    },
    security: {
        label: "Security Code",
        placeholder: "Security Code"
    }
};

export default {
    props: {},
    watch: {},
    components: {
        FormWizard,
        TabContent,
        VxCard,
        VCreditCard,
        flatPickr
    },
    data() {
        return {
            ifValidCar: false,
            isOpenTerms: false,
            activeLoading: false,
            customMessages,
            thisIsMyCarConfirm: false,
            thisconditionConfirm: false,
            thisAcknowledgeConfirm: false,
            carRegistrationPayload: {
                registrationPlate: "",
                registrationState: ""
            },
            deductionAmount: "",
            discountPercentage: 0,
            priceAfterDeduction: null,
            carId: "",
            stateList: [],
            passData: [],
            cardData: null,
            selectedPass: "",
            promo: "",
            translations,
            fileURL: "https://bhpstorage.blob.core.windows.net/cdn/Terms.pdf"
        };
    },
    async created() {
        await this.ValidateProfile();
        this.stateList = treeMixData.searchListOfStates();

        await this.loadSeasonPass();
        this.carId = this.uniqueId();
    },

    methods: {
        async ValidateProfile() {
            const request = await profileData.ValidateMyProfile();
            if (!request.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: request.message,
                    color: "danger",
                    position: "top-center"
                });
                this.$router.push(`/MyProfile`);
            }
        },
        changeletter() {
            this.carRegistrationPayload.registrationPlate = this.carRegistrationPayload.registrationPlate.toUpperCase();
        },
        uniqueId() {
            return uuid();
        },
        creditInfoChanged(values) {
            this.cardData = {};
            this.cardData = values;
        },
        changeCar() {
            this.thisIsMyCarConfirm = false;
            this.ifValidCar = false;
            this.carRegistrationPayload = {
                registrationState: ""
            };
        },

        async validatePromo() {
            const validate = await promoData.validatePrompCode(this.promo, this.selectedPass.id);

            if (validate && validate.succeeded === false) {
                this.$vs.notify({
                    title: "Error",
                    text: `${validate.message}`,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }

            this.discountPercentage = validate.discountPercentage;
            this.deductionAmount =
                (this.selectedPass.priceIncGst / 100) * this.discountPercentage;

            this.priceAfterDeduction =
                this.selectedPass.priceIncGst - this.deductionAmount;
        },

        async removePromo() {
            this.priceAfterDeduction = this.selectedPass.priceIncGst;
            this.discountPercentage = 0;
            this.promo = "";
        },

        async findCar() {
            const results = await carData.findCar(
                this.carRegistrationPayload.registrationPlate,
                this.carRegistrationPayload.registrationState
            );
            if (!results.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: results.message,
                    color: "danger",
                    position: "top-center"
                });
                this.ifValidCar = false;
                return;
            }
            var carDetail = results.data;
            if (carDetail) {
                if (new Date(carDetail.expiry_date) <= new Date()) {
                    this.$vs.notify({
                        title: "Error",
                        text: "Registration for this car has already expired, please try again.",
                        color: "danger",
                        position: "top-center"
                    });
                    return;
                }
                this.ifValidCar = true;
                this.carRegistrationPayload.registrationPlate = carDetail.plate;
                this.carRegistrationPayload.registrationExpiryDate = carDetail.expiry_date;
                this.carRegistrationPayload.registrationStatus = carDetail.status;
                this.carRegistrationPayload.model = carDetail.carModel;
            } else {
                this.ifValidCar = false;
                this.$vs.notify({
                    title: "Error",
                    text: "Registration not found.",
                    color: "danger",
                    position: "top-center"
                });
            }
        },
        async bookPass() {

            if (this.discountPercentage !== 100) {
                if (!this.cardData) {
                    this.$vs.notify({
                        title: "Error",
                        text: "Please enter a valid card details",
                        color: "danger",
                        position: "top-center"
                    });
                    return;
                }

                const dateDiff = moment.utc(
                    moment(this.cardData.expiration, "MM/YY").diff(
                        moment(new Date(), "MM/YY")
                    )
                );

                if (dateDiff < 0) {
                    this.$vs.notify({
                        title: "Error",
                        text: "Card Not Valid",
                        color: "danger",
                        position: "top-center"
                    });
                    return;
                }
            }

            this.carRegistrationPayload.productId = this.selectedPass.id;
            this.carRegistrationPayload = {
                ...this.carRegistrationPayload,
                ...this.cardData,
                carId: this.carId,
                promoCode: this.promo,
                discountPercentage: this.discountPercentage
            };

            //Active Loading

            this.activeLoading = true
            this.$vs.loading()

            const request = await carData.SaveCarRegistration(this.carRegistrationPayload);

            //Disable Loading
            this.activeLoading = false
            this.$vs.loading.close()

            if (!request.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: request.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }
            this.$vs.notify({
                title: "Success",
                text: "Record Saved",
                color: "success",
                position: "top-center"
            });
            this.goBack();
        },
        async handleNext() {
            const success = await this.$refs.form.validate();
            if (!success) {
                return;
            }

            if (!this.thisIsMyCarConfirm) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please confirm the car details.",
                    color: "danger",
                    position: "top-center"
                });
                return false;
            }
            if (!this.thisconditionConfirm) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please confirm all the Terms and Conditions.",
                    color: "danger",
                    position: "top-center"
                });
                return false;
            }
            if (!this.thisAcknowledgeConfirm) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please confirm all the Terms and Conditions.",
                    color: "danger",
                    position: "top-center"
                });
                return false;
            }
            return true;
        },
        async loadSeasonPass() {
            this.passData = await productData.SearchProductsOnSale();
        },
        async setSelectedPass(pass) {

            let data = await productData.validateProductPurchase(pass.id);
            if (!data.succeeded) {
                this.$vs.notify({
                    title: "Required",
                    text: data.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }

            this.selectedPass = pass;
            this.priceAfterDeduction = this.selectedPass.priceIncGst;
        },
        handlePass() {
            if (this.selectedPass === "") {
                this.$vs.notify({
                    title: "Required",
                    text: "Please select season pass",
                    color: "danger",
                    position: "top-center"
                });
                return false;
            } else {
                return true;
            }
        },
        handleOpenTerms() {
            this.isOpenTerms = !this.isOpenTerms;
        },
        closeWindow() {
            this.isOpenTerms = false;
        },
        goBack() {
            this.$router.push("/Vehicles");
        }
    }
};
</script>

<style lang="scss" scoped>
.card-overflow {
    overflow: overlay;
}

.text-left {
    float: left;
}

.text-right {
    float: right;
    margin-top: 5px;

    @media screen and (max-width: 1200px) {
        float: left;
    }
}
</style>
